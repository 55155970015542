<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2">
        <h4>Eligibility Failures</h4>
      </b-col>
    </b-row>
    <b-row @keyup.enter="applyFilters">
      <b-col cols="2">
        <b-form-group label="Client"
                      label-for="input-client">
          <b-form-select id="input-client"
                         v-model="filters.client_id"
                         :disabled="loading"
                         :options="client_list"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="First Name"
                      label-for="input-first-name">
          <b-form-input id="input-first-name"
                        v-model="filters.first_name"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Last Name"
                      label-for="input-last-name">
          <b-form-input id="input-last-name"
                        v-model="filters.last_name"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Birthdate"
                      label-for="input-birthdate">
          <b-form-input id="input-birthdate"
                        placeholder="MM-DD-YYYY"
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        v-model="filters.birthdate"
                        type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Email"
                      label-for="input-email">
          <b-form-input id="input-email"
                        v-model="filters.email"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4 d-flex" @keyup.enter="applyFilters">
      <b-col cols="1">
        <b-form-group label="Flagged"
                      label-for="input-flagged">
          <b-form-checkbox
            id="input-flagged"
            v-model="filters.flagged"
            name="flagged"
            :value="true"
            :unchecked-value="null"/>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group label="Found"
                      label-for="input-member-found">
          <b-form-checkbox
            id="input-member-found"
            v-model="filters.match_found"
            name="flagged"
            :value="true"
            :unchecked-value="null"/>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Status"
                      label-for="input-user-status">
          <b-form-select
            id="input-user-status"
            v-model="filters.user_status"
            :options="user_status_options"/>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Attempt Date"
                      label-for="input-attempt-date">
          <b-form-input id="input-attempt-date"
                        placeholder="MM-DD-YYYY"
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        v-model="filters.attempt_date"
                        type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="First Attempt"
                      label-for="input-first-attempt">
          <b-form-input id="input-first-attempt"
                        placeholder="MM-DD-YYYY"
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        v-model="filters.first_attempt_date"
                        type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Last Attempt"
                      label-for="input-last-attempt">
          <b-form-input id="input-last-attempt"
                        placeholder="MM-DD-YYYY"
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        v-model="filters.last_attempt_date"
                        type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1" class="align-self-end">
        <div class="form-group">
          <b-button variant="primary" style="width: 100px" @click="applyFilters">Search</b-button>
        </div>
      </b-col>
      <b-col cols="1" class="align-self-end">
        <div class="form-group">
          <b-button variant="warning" style="width: 100px" class="ml-0" @click="resetFilters">Reset</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p><b>Number of results: </b>{{list.total}}</p>
      </b-col>
      <b-table class="member-list"
               :fields="tableFields"
               @row-clicked="item => $set(item, '_showDetails', !item._showDetails)"
               :items="list.items"
               :tbody-tr-class="rowClass"
               show-empty empty-text="No attempts found">
        <template #cell(first_name)="data">
          <span>{{ data.item.best_match.first_name }}</span>
        </template>
        <template #cell(last_name)="data">
          <span>{{ data.item.best_match.last_name }}</span>
        </template>
        <template #cell(birthdate)="data">
          <span>{{ formatDate(data.item.best_match.birthdate, 'YYYYMMDD') }}</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.item.best_match.created_at, 'MMMM DD YYYY, hh:mm') }}</span>
        </template>
        <template #cell(client_id)="data">
          <span>{{ `${data.item.display_name} (ID: ${data.item.onboarding_client_id})` }}</span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status === 'completed'" class="badge badge-success">completed</span>
          <span v-else-if="data.item.status === 'pending'" class="badge badge-warning">pending</span>
          <span v-else-if="data.item.status === 'failed'" class="badge badge-danger">failed</span>
          <span v-else>{{ data.item.status }}</span>
        </template>
        <template #cell(excluded_reason)="data">
          <div v-if="data.item.processed === 1">
            <span v-if="isNullOrEmpty(data.item.best_match.best_match)">No match found</span>
            <div v-else>
              <span :id="`list-excluded-reason-${data.item.uuid}`"
                    v-if="isNullOrEmpty(data.item.best_match.best_match.details._details.message)" class="badge badge-warning">Unknown Error</span>
              <span v-else>{{ data.item.best_match.best_match.details._details.message }}</span>
              <b-tooltip
                v-if="isNullOrEmpty(data.item.best_match.best_match.details._details.message)"
                :target="`list-excluded-reason-${data.item.uuid}`" triggers="hover">
                The system was unable to know why this member was excluded.
              </b-tooltip>
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template #cell(match_found)="data">
          <div v-if="data.item.processed === 1">
            <span v-if="data.item.match_found === 1" class="badge badge-success">yes</span>
            <span v-else class="badge badge-danger">no</span>
          </div>
          <div v-else>-</div>
        </template>
        <template #row-details="row">
          <RowDetails :row="row"/>
        </template>
        <template #cell(score)="row">
          <ScoreBadge :row="row"/>
        </template>
        <template #cell(tracking)="row">
          <span v-if="hasTrackingInfo(row.item)" class="badge badge-success">yes</span>
          <span v-else class="badge badge-danger">no</span>
        </template>
        <template #cell(more)="data">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="data.item.processed === 1"
              variant="primary"
              @click="$set(data.item, '_showDetails', !data.item._showDetails)"
              class="ml-0 btn btn-info"
              size="bg"
              style="min-width: 50px">Details
            </b-button>
            <b-button
              v-if="data.item.processed === 1"
              variant="primary"
              class="ml-2 btn btn-success"
              size="bg"
              @click="openActionsModal(data, $event.target)"
              style="min-width: 50px">Actions
            </b-button>
            <b-spinner variant="primary" :id="`list-table-actions-${data.item.uuid}`" v-if="data.item.processed === 0" label="Spinning"/>
            <b-tooltip
              v-if="data.item.processed === 0"
              :target="`list-table-actions-${data.item.uuid}`" triggers="hover">
              This attempt is being processed. This can take a few minutes.
            </b-tooltip>
          </div>
        </template>
      </b-table>
      <infinite-loading class="w-100" ref="infiniteLoader" @infinite="infiniteHandler">
        <template v-slot:no-results>
          <div class="d-flex justify-content-center flex-row align-items-center">
            <feather class="no-results-icon" type="meh"></feather>
            <h5 style="margin-left: 10px;">&nbsp;</h5>
          </div>
        </template>
        <template v-slot:no-more>&nbsp;</template>
        <template v-slot:error>&nbsp;</template>
      </infinite-loading>
      <b-modal :id="infoModal.id" hide-footer :title="infoModal.title" @hide="resetActionsModal" size="xl">
        <Actions
          :agg_uuid="infoModal.content.agg_uuid"
          :user_status="infoModal.content.user_status"
          :flagged="infoModal.content.flagged"
          :user_notes="infoModal.content.user_notes"/>
      </b-modal>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment-timezone';
import { isNullOrEmpty } from '@/helpers';
import Actions from '@/components/EligibilityFailures/Actions.vue';
import { USER_STATUS } from '@/components/EligibilityFailures/constants';
import RowDetails from '@/components/EligibilityFailures/RowDetails.vue';
import ScoreBadge from '@/components/EligibilityFailures/ScoreBadge.vue';

export default {
  name: 'ListEligibilityAttempts',
  data() {
    return {
      selectedItem: {},
      loading: false,
      filters: {
        client_id: '',
        first_name: null,
        last_name: null,
        email: null,
        birthdate: null,
        attempt_date: null,
        first_attempt_date: null,
        last_attempt_date: null,
        flagged: null,
        match_found: null,
        status: null,
        user_status: null,
      },
      client_list: [
        { value: '', text: 'All (Default)' },
      ],
      tableFields: [
        { key: 'first_attempt_datetime', label: 'Fist Attempt Date' },
        { key: 'last_attempt_datetime', label: 'Last Attempt Date' },
        { key: 'client_id', label: 'Client' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email', label: 'Email', tdClass: 'eligibility-failures-email-column' },
        { key: 'birthdate', label: 'DOB' },
        { key: 'excluded_reason', label: 'Excluded Reason' },
        { key: 'user_status', label: 'Status' },
        { key: 'match_found', label: 'Member Found' },
        { key: 'attempts_count', label: 'Attempt Count' },
        { key: 'score', label: 'Score' },
        { key: 'tracking', label: 'Tracking' },
        { key: 'more', label: '' },
      ],
      user_status_options: [
        ...USER_STATUS,
        { value: '', text: 'All' },
      ],
      list: {
        page: 0,
        limit: 20,
        items: [],
        total: 0,
      },
      infoModal: {
        id: 'eligibility-failures-actions-modal',
        title: 'Eligibility Failure Actions',
        content: {
          agg_uuid: '',
          user_status: '',
          flagged: false,
          user_notes: '',
        },
      },
    };
  },
  watch: {
    filters: {
      handler(value) {
        this.$store.commit('Onboarding/EligibilityFailures/setListFilters', value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      storedFilters: 'Onboarding/EligibilityFailures/getListFilters',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  components: {
    ScoreBadge,
    RowDetails,
    InfiniteLoading,
    Actions,
  },
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    this.filters = this.storedFilters;
    this.applyFilters();
    await this.loadFilters();
  },
  methods: {
    formatDate(date, format) {
      const parsedDate = moment(date);
      if (!parsedDate.isValid()) {
        return '-';
      }

      return parsedDate.format(format);
    },
    async loadFilters() {
      const { data } = await axios.get('v1/onboarding/eligibility-attempts/filters');

      data.clients.forEach(client => {
        this.client_list.push({ value: client.id, text: `${client.display_name} (ID: ${client.id})` });
      });
    },
    async loadAttemptList() {
      const params = Object.assign({}, this.appliedFilters);
      params.page = this.list.page;
      params.limit = this.list.limit;

      const { data } = await axios.get('v1/sftp/eligibility-failures', { params });

      // find the best match for each attempt group
      data.items.forEach((item, i) => {
        data.items[i].best_match = this.getBestMatch(item);
      });

      return data;
    },
    resetFilters() {
      this.filters = {
        client_id: null,
        first_name: null,
        last_name: null,
        email: null,
        birthdate: null,
        attempt_date: null,
        first_attempt_date: null,
        last_attempt_date: null,
        flagged: null,
        match_found: null,
        status: null,
        user_status: 'unprocessed',
      };
      this.applyFilters();
    },
    applyFilters() {
      this.list.page = 0;
      this.list.limit = 100;
      this.list.items = [];
      this.list.total = 0;

      const { data, ...filtersWithoutData } = this.filters;
      this.appliedFilters = filtersWithoutData;
      Object.entries(this.appliedFilters).forEach(([ filterName, value ]) => {
        if (value == null || value === '') {
          delete this.appliedFilters[filterName];
        }
      });

      if (this.$refs.infiniteLoader) {
        this.$refs.infiniteLoader.stateChanger.reset();
      }
    },
    infiniteHandler($state) {
      this.loadAttemptList().then(data => {
        this.list.items = this.list.items.concat(data.items);
        this.list.total = data.total;
        this.list.page += 1;
        if (data.items.length) {
          $state.loaded();
          return;
        }
        $state.complete();
      }).catch(err => {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
        $state.error();
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return '';
      }

      return item.flagged ? 'table-danger' : '';
    },
    isNullOrEmpty,
    openActionsModal(row, button) {
      this.infoModal.content.agg_uuid = row.item.uuid;
      this.infoModal.content.flagged = !!row.item.flagged;
      this.infoModal.content.user_status = row.item.user_status;
      this.infoModal.content.user_notes = row.item.user_notes || '';
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetActionsModal() {
      this.infoModal.content = {
        agg_uuid: '',
        user_status: '',
        flagged: false,
        user_notes: '',
      };
    },
    getBestMatch(item) {
      let bestMatch = {
        weight: 0,
        match: null,
      };

      item.attempts.forEach(result => {
        if (result.best_match && (result.score > bestMatch.weight || bestMatch.weight === 0)) {
          bestMatch = {
            weight: result.score,
            match: result,
          };
        }
      });

      // if no match was found, return the last attempt by date
      if (!bestMatch.match) {
        // eslint-disable-next-line prefer-destructuring
        bestMatch.match = item.attempts.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        )[0];
      }

      return bestMatch.match;
    },
    hasTrackingInfo(item) {
      // eslint-disable-next-line no-restricted-syntax
      for (const attempt of item.attempts) {
        if (!isNullOrEmpty(attempt.tracking_info)) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style>
.member-list {
  font-size: 15px;
}

.member-list > tbody > tr {
  cursor: pointer;
}

.notes-card > .card-body {
  padding-left: 0;
  padding-bottom: 0;
}

.eligibility-failures-email-column {
  max-width: 200px;
  word-wrap: break-word;
}
</style>
