<template>
  <div>
    <div :id="`list-score-badge-table-actions-${row.item.uuid}`">
      <b-badge pill v-if="show_badge" :variant="variant">{{letter}}</b-badge>
      <div v-else>-</div>
    </div>
    <b-tooltip v-if="row.item.processed" :target="`list-score-badge-table-actions-${row.item.uuid}`" triggers="hover">{{label}}</b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      letter: null,
      variant: null,
      show_badge: false,
      label: '',
    };
  },
  beforeMount() {
    if (!this.row.item.best_match?.best_match) {
      this.label = 'No match found';
      return;
    }

    this.show_badge = true;
    const { score } = this.row.item.best_match.best_match;
    if (score <= 200) {
      this.letter = 'A';
      this.variant = 'success';
      this.label = 'A very close match was found';
    } else if (score >= 300 && score < 400) {
      this.letter = 'B';
      this.variant = 'warning';
      this.label = 'No match found';
      this.label = 'A close match was found';
    } else {
      this.letter = 'C';
      this.variant = 'danger';
      this.label = 'A match was found by some fields';
    }
  },
};
</script>
