/* eslint-disable import/prefer-default-export */

const USER_STATUS = [
  { value: 'unprocessed', text: 'Unprocessed' },
  { value: 'resolved', text: 'Resolved' },
  { value: 'processing', text: 'Processing' },
  { value: 'ignored', text: 'Ignored' },
];

const NOTES_STATUS_ENUM = {
  FAILURE: 0,
  SUCCESS: 1,
  THRESHOLD: 2,
  SKIPPED: 3,
};

export {
  USER_STATUS,
  NOTES_STATUS_ENUM,
};
