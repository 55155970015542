<template>
  <b-card class="notes-card">
    <div v-if="row.item.match_found === 1">
      <h4 class="mb-2 ml-4">Notes</h4>
      <MatchNotes :notes="row.item.best_match.best_match.details._details.notes"/>
      <router-link :to="`/onboarding/eligibility-failures/${row.item.best_match.attempt_uuid}`"
                   variant="primary"
                   class="ml-4 mb-2 btn btn-success"
                   target="_blank"
                   size="sm"
                   style="min-width: 50px">See match details</router-link>
      <hr>
    </div>
    <h4 class="mb-3 ml-4 mt-3">Attempts</h4>
    <div class="ml-4">
      <b-table striped hover :fields="tableFields" :items="row.item.attempts">
        <template #cell(more)="data">
          <div v-if="data.item.processed === 1" class="d-flex justify-content-center align-items-center">
            <router-link :to="`/onboarding/eligibility-failures/${data.item.attempt_uuid}`"
                         variant="primary"
                         target="_blank"
                         class="ml-4 mb-2 btn btn-info"
                         size="sm">More Details</router-link>
          </div>
          <div v-else>
            <b-spinner variant="primary" :id="`details-table-actions-${data.item.attempt_uuid}`" label="Spinning"></b-spinner>
            <b-tooltip
              :target="`details-table-actions-${data.item.attempt_uuid}`" triggers="hover">
              This attempt is being processed. This can take a few minutes.
            </b-tooltip>
          </div>
        </template>
        <template #cell(birthdate)="data">
          <span>{{ formatDate(data.item.birthdate, 'YYYYMMDD') }}</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.item.created_at, 'MMMM DD YYYY, hh:mm') }}</span>
        </template>
        <template #cell(tracking)="row">
          <span v-if="hasTrackingInfo(row.item)" class="badge badge-success">yes</span>
          <span v-else class="badge badge-danger">no</span>
        </template>
        <template #cell(excluded_reason)="data">
          <div v-if="data.item.processed === 1">
            <div v-if="data.item.best_match">
              {{data.item.best_match.details._details.message}}
            </div>
            <div v-else>
              No match found
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import MatchNotes from '@/components/EligibilityFailures/MatchNotes.vue';
import moment from 'moment-timezone';
import { isNullOrEmpty } from '@/helpers';

export default {
  components: { MatchNotes },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableFields: [
        { key: 'created_at', label: 'Date' },
        { key: 'first_name', label: 'First Name', tdClass: this.getFieldClassFromMatchValue },
        { key: 'last_name', label: 'Last Name', tdClass: this.getFieldClassFromMatchValue },
        { key: 'email', label: 'Email', tdClass: this.getFieldClassFromMatchValue },
        { key: 'birthdate', label: 'DOB', tdClass: this.getFieldClassFromMatchValue },
        { key: 'tracking', label: 'Tracking' },
        { key: 'excluded_reason', label: 'Excluded Reason' },
        { key: 'more', label: '' },
      ],
    };
  },
  beforeMount() {
    this.row.item.attempts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  },
  methods: {
    getFieldClassFromMatchValue(_, column, row) {
      if (!row.best_match) {
        return '';
      }

      // yolo: onboarding is using birthdate instead of date_of_birth
      if (column === 'birthdate') {
        column = 'date_of_birth';
      }

      if (!row.best_match.details._details.info[column]) {
        console.warn('Unknown column', column, row);
        return '';
      }

      switch (row.best_match.details._details.info[column].match) {
        case 'success':
          return 'font-color-green';
        case 'threshold':
          return 'font-color-yellow';
        case 'failure':
          return 'font-color-red';
        case 'skipped':
          return 'font-color-grey';
        default:
          console.warn('Unknown match value', row.best_match.details._details.info[column].match, column);
          return '';
      }
    },
    formatDate(date, format, tz) {
      let parsedDate = moment(date);
      if (tz) {
        parsedDate = moment.tz(date, tz);
      }

      if (!parsedDate.isValid()) {
        return '-';
      }

      return parsedDate.format(format);
    },
    hasTrackingInfo(item) {
      return !isNullOrEmpty(item.tracking_info);
    },
  },
};
</script>

<style>
  .font-color-green {
    color: green;
  }

  .font-color-red {
    color: red;
  }

  .font-color-yellow {
    color: #ff7900;
  }

  .font-color-grey {
    color: grey;
  }
</style>
