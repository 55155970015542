<template>
  <ul>
    <li class="mb-1" v-for="(note, key) in notes" :class="getClass(note)" :key="key">{{ note.message }}</li>
  </ul>
</template>

<script>
import { NOTES_STATUS_ENUM } from '@/components/EligibilityFailures/constants';

export default {
  props: {
    notes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getClass(note) {
      switch (note.status) {
        case NOTES_STATUS_ENUM.SUCCESS:
          return 'font-color-green';
        case NOTES_STATUS_ENUM.FAILURE:
          return 'font-color-red';
        case NOTES_STATUS_ENUM.THRESHOLD:
          return 'font-color-yellow';
        case NOTES_STATUS_ENUM.SKIPPED:
          return 'font-color-grey';
        default:
          console.error('Unknown note status', note.status);
          return '';
      }
    },
  },
};
</script>

<style scoped>
.font-color-green {
  color: green;
}

.font-color-red {
  color: red;
}

.font-color-yellow {
  color: #ff7900;
}

.font-color-grey {
  color: grey;
}
</style>
