<template>
  <b-col cols="12" class="mt-2">
      <div class="form-group">
        <b-row>
          <b-form-group label="Status"
                        label-for="user-status-action"
                        style="width: 10%">
          <b-form-select
              id="user-status-action"
              class="mt-2" v-model="form.user_status"
              :state="!$v.form.user_status.$invalid"
              :options="user_status_options"/>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-checkbox
              class="mt-3 remove-font-weight"
              v-model="form.flagged"
              name="flagged"
              :value="true"
              :unchecked-value="false">
            Flag member for review
          </b-form-checkbox>
        </b-row>
        <b-row>
          <b-textarea v-model="form.user_notes" class="mt-4" size="lg" rows="5"/>
        </b-row>
        <b-row>
          <b-button :disabled="this.submitting" type="button" class="btn btn-success mt-4"
                    @click.prevent="submit">Submit</b-button>
        </b-row>
      </div>
  </b-col>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { USER_STATUS } from '@/components/EligibilityFailures/constants';

export default {
  name: 'EligibilityFailuresActions',
  data() {
    return {
      submitting: false,
      form: {
        user_status: '',
        flagged: false,
        user_notes: '',
      },
      user_status_options: USER_STATUS,
    };
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      form: {
        user_status: { required },
        flagged: { required },
      },
    };
  },
  props: {
    agg_uuid: {
      type: String,
      required: true,
    },
    user_status: {
      type: String,
      required: true,
    },
    flagged: {
      type: Boolean,
      required: true,
    },
    user_notes: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.form.user_status = this.user_status;
    this.form.flagged = this.flagged;
    this.form.user_notes = this.user_notes;
  },
  methods: {
    async submit() {
      if (this.$v.$invalid) {
        this.$noty.error('Please fill out all required fields');
        return;
      }

      try {
        this.submitting = true;
        await axios.put(`v1/sftp/eligibility-failures/${this.agg_uuid}`, this.form);
        this.$noty.success('Saved');
      } catch (err) {
        if (err.response.status === 400) {
          this.$noty.error('Please fill out all required fields');
          return;
        }

        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
